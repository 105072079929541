import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = ({
    backDrop: {
        position: 'absolute',
        backgroundColor: 'grey',
        width: '100%',
        height: '100%',
        zIndex: '210',
        margin: '0',
        opacity: '0.4',
        position: 'fixed'
    },
    display: {
        display: "none"
    }
})

class BackDrop extends Component {
    onClickClose = () => {
        this.props.handleClick(false)
    }
    render() {
        const { classes } = this.props;
        let display = classes.display;
        if (this.props.menuAnimation === true) {
            display = classes.backDrop
        } else if (this.props.menuAnimation === false) {
            display = classes.display;
        }

        return (
            <div className={display} onClick={this.onClickClose} ></div>
        )
    }
}

export default withStyles(styles)(BackDrop);