export default {
    palette: {
      primary: {
        light: '#33c9dc',
        main: '#00bcd4',
        dark: '#008394',
        contrastText: '#fff'
      },
      secondary: {
        light: '#ff6333',
        main: '#ff3d00',
        dark: '#b22a00',
        contrastText: '#fff'
      }
    }, 
    typography: {
      useNextVariants: true
    },
    spreadThis: {
      form: {
        textAlign: 'center'
      },
      image: {
          margin: '20px auto 20px auto'
      },
      pageTitle: {
          margin: '10px auto 10px auto'
      },
      textField: {
          margin: '20px auto 20px auto'
      },
      button:{
          marginTop: 20,
          position: 'relative'
      },
      customError: {
          color: 'red',
          fontSize: '0.8rem',
          marginTop: 10
      },
      progress: {
          position: 'absolute'
      }
    },
    menuBarContainer: {
        position: 'absolute',
        bottom: '0',
        height:'100vh',
        width: '230px',
        backgroundColor: 'red',
        left: '0px',
        zIndex: '100'
    },
    openMenu: {
        position: 'absolute',
        bottom: '0',
        height:'100vh',
        width: '230px',
        backgroundColor: 'red',
        zIndex: '100',
        transform: 'translate(0)',
        transitionDuration: '1s',
        transitionTimingFunction: 'ease-in'
    },
    closeMenu: {
        position: 'absolute',
        bottom: '0',
        height:'100vh',
        width: '230px',
        backgroundColor: 'red',
        zIndex: '100',
        transform: 'translate(-230px)',
        transitionDuration: '1s',
        transitionTimingFunction: 'ease-in'
    },
    exitIcon: {
      position: 'absolute',
      top: '5px',
      right: '5px'
    }
  }