import React, { Component, Fragment } from "react";
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Link } from "react-router-dom";
import HeaderAr from "../../../components/ArabicComponents/header-eng/header-ar";
import FooterAr from "../../../components/ArabicComponents/footer-ar/footer-ar";
import axios from "axios";

import PhoneIcon from "@material-ui/icons/Phone";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import AllInboxIcon from "@material-ui/icons/AllInbox";
import PrintIcon from "@material-ui/icons/Print";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";

import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import { withStyles, createStyles } from "@material-ui/core/styles";

import emailjs from "emailjs-com";

import "./contactus-ar.css";
const styles = (theme) =>
  createStyles({
    root: {
      width: "80%",
      margin: "2% 10%",
      "& .MuiFormLabel-root": {
        position: "absolute",
        right: "0px",
        marginRight: "30px",
        textAlign: "right",
      },
    },
  });
export class ContactusAr extends Component {
  state = {
    name: "",
    email: "",
    message: "",
    phone: "",
    errors: {},
    loading: false,
    form: false,
  };

  onFormSubmit = async (event) => {
    event.preventDefault();
    this.setState({ errors: {}, loading: true });
    const data = {
      name: this.state.name,
      email: this.state.email,
      phone: this.state.phone,
      message: this.state.message,
    };
    // console.log(data);

    emailjs
      .send("service_spwwr54", "template_6pgkh9p", data, "V5q4iP4CPtUDxaap_")
      .then(
        (result) => {
          console.log(result.text);
          this.setState({ errors: {}, loading: false, form: true });
          // this.setState({ form: true });
          // alert("Message Sent Successfully");
          // window.location.reload(false);
          // alert("Message Sent");
        },
        (error) => {
          console.log(error.text);
          this.setState({ form: false, loading: false });
          alert("Message Not Sent");
        }
      );

    // await axios
    //   .post("/email", data)
    //   .then((res) => {
    //     console.log(res);
    //     this.setState({ errors: {}, loading: false, form: true });
    //   })
    //   .catch((err) =>
    //     this.setState({ errors: err.response.data, loading: false })
    //   );
  };

  changeHandler = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleParagraphChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value.split("\\n").join("\n"),
    });
  };

  render() {
    const { errors } = this.state;
    const { classes } = this.props;
    const contactform =
      this.state.form === false ? (
        <Fragment>
          <form noValidate onSubmit={this.onFormSubmit}>
            <TextField
              className={classes.root}
              InputLabelProps={{
                right: "0px",
              }}
              id="standard-secondary"
              label="اسم"
              color="primary"
              name="name"
              type="text"
              variant="filled"
              helperText={errors.name}
              onChange={this.changeHandler}
              error={errors.name ? true : false}
              required
            />
            <TextField
              className={classes.root}
              id="standard-secondary"
              label="البريد الالكتروني"
              color="primary"
              name="email"
              type="email"
              variant="filled"
              helperText={errors.email}
              onChange={this.changeHandler}
              error={errors.email ? true : false}
              required
            />
            <TextField
              className={classes.root}
              id="standard-secondary"
              label="رقم الهاتف"
              color="primary"
              name="phone"
              type="text"
              variant="filled"
              onChange={this.changeHandler}
            />
            <TextField
              className={classes.root}
              id="standard-secondary"
              label="الرسالة"
              color="primary"
              name="message"
              type="text"
              helperText={errors.message}
              onChange={this.handleParagraphChange}
              error={errors.message ? true : false}
              variant="filled"
              multiline
              rows={4}
              required
            />
            <Button
              variant="contained"
              color="primary"
              type="submit"
              className="ContactFormBtn"
            >
              <span className="ContactFormBtnTitle">ارسال</span>
              {this.state.loading && <CircularProgress size={30} />}
            </Button>
          </form>
        </Fragment>
      ) : (
        <div
          className="Enquiry-Form-Box-top"
          style={{ textAlign: "center", margin: "75px 0" }}
        >
          <h1 className="EnquiryFormTitle">Successfully Sent</h1>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            className="EnquiryFormBtn"
            component={Link}
            to="/"
          >
            <span className="EnquiryFormBtnTitle">Return Home</span>
          </Button>
        </div>
      );
    return (
      <Fragment>
        <HeaderAr handleClick={this.props.handleClick} />
        <div
          className="ContactFormBackground"
          style={{ height: "100%", width: "100%" }}
        >
          <div className="Enquiry-Form-Box-top" style={{ textAlign: "center" }}>
            <span className="ContactFormTitle">تواصل معنا</span>
          </div>

          <div className="contact-1-row">
            <Card style={{ marginRight: "0" }} className="Contact-Form-Box-ar">
              {contactform}
            </Card>

            <div className="contact-info-box-ar">
              <div className="contact-info-box-1-ar">
                <h3 className="contact-info-title-ar">موقعنا</h3>
                <p className="contact-info-desc-ar">
                  عمان - الاردن
                  <br />
                  راس العين - شارع القدس
                  <br />
                  مجمع الزهراء التجاري <br className="breakline" />, رقم ١٠٠
                  <br />
                </p>
              </div>

              <div className="contact-info-box-2-ar">
                <h3 className="contact-info-title-ar">معلومات التواصل</h3>
                <div className="list-column-ar">
                  <a
                    href="tel:+96264743031"
                    className="icon-info-ar-container"
                    style={{ marginTop: "0" }}
                  >
                    <span className="icon-info-ar">+٩٦٢</span>
                    <span className="icon-info-ar">٦</span>
                    <span className="icon-info-ar"> ٣٠٣١ ٤٧٤ </span>
                    <PhoneIcon
                      style={{ marginLeft: "5px" }}
                      className="icon-font"
                    />
                  </a>
                  <a
                    href="tel:+96264743032"
                    className="icon-info-ar-container"
                    style={{ marginTop: "0" }}
                  >
                    <span className="icon-info-ar">+٩٦٢</span>
                    <span className="icon-info-ar">٦</span>
                    <span className="icon-info-ar"> ٣٠٣٢ ٤٧٤ </span>
                    <PhoneIcon
                      style={{ marginLeft: "5px" }}
                      className="icon-font"
                    />
                  </a>
                  <a
                    href="tel:+96264743033"
                    className="icon-info-ar-container"
                    style={{ marginTop: "0" }}
                  >
                    <span className="icon-info-ar">+٩٦٢</span>
                    <span className="icon-info-ar">٦</span>
                    <span className="icon-info-ar"> ٣٠٣٣ ٤٧٤ </span>
                    <PhoneIcon
                      style={{ marginLeft: "5px" }}
                      className="icon-font"
                    />
                  </a>
                  <div className="icon-info-ar-container">
                    <span className="icon-info-ar" style={{ float: "right" }}>
                      info@azzahrapp.com
                    </span>
                    <MailOutlineIcon
                      style={{ marginLeft: "5px" }}
                      className="icon-font"
                    />
                  </div>
                  <div className="icon-info-ar-container">
                    <span className="icon-info-ar">+٩٦٢</span>
                    <span className="icon-info-ar">٦</span>
                    <span className="icon-info-ar"> ٣٠٣٨ ٤٧٤ </span>
                    <PrintIcon
                      style={{ marginLeft: "5px" }}
                      className="icon-font"
                    />
                  </div>
                  <div className="icon-info-ar-container">
                    <span className="icon-info-ar">+٩٦٢</span>
                    <span className="icon-info-ar">٧٧٧٦٦٠٦٠٦</span>
                    <WhatsAppIcon
                      style={{ marginLeft: "5px" }}
                      className="icon-font"
                    />
                  </div>
                  <a
                    href="tel:+962777600017"
                    className="icon-info-ar-container"
                  >
                    <span className="icon-info-ar">+٩٦٢</span>
                    <span className="icon-info-ar">٧٧٧٦٠٠٠١٧</span>
                    <PhoneAndroidIcon
                      style={{ marginLeft: "5px" }}
                      className="icon-font"
                    />
                  </a>

                  <div className="icon-info-ar-container">
                    <span className="icon-info-ar" style={{ direction: "rtl" }}>
                      ص.ب: ٣٤٠٩٣٥ عمان
                      <br />
                      ١١١٣٤ , الاردن
                    </span>
                    <AllInboxIcon
                      style={{ marginLeft: "5px" }}
                      className="icon-font"
                    />
                  </div>
                </div>
              </div>

              <div className="contact-info-box-3-ar">
                <h3 className="contact-info-title-ar title-working-hours">
                  ساعات العمل
                </h3>
                <Card
                  style={{
                    width: "90%",
                    float: "right",
                    clear: "right",
                    justifyContent: "space-between",
                    marginTop: "10px",
                  }}
                >
                  <table
                    style={{
                      float: "right",
                      clear: "right",
                      justifyContent: "space-between",
                      width: "100%",
                      padding: "2%",
                    }}
                  >
                    <tr>
                      <th
                        className="contact-info-desc-ar"
                        style={{ fontWeight: " 600" }}
                      >
                        الي
                      </th>
                      <th
                        className="contact-info-desc-ar"
                        style={{ fontWeight: " 600" }}
                      >
                        من
                      </th>
                      <th
                        className="contact-info-desc-ar"
                        style={{ fontWeight: " 600" }}
                      >
                        اليوم
                      </th>
                    </tr>
                    <tr>
                      <td className="contact-info-desc-ar">٦:٠٠م</td>
                      <td className="contact-info-desc-ar">٨:٠٠ص</td>
                      <td className="contact-info-desc-ar">السبت</td>
                    </tr>
                    <tr>
                      <td className="contact-info-desc-ar">٦:٠٠م</td>
                      <td className="contact-info-desc-ar">٨:٠٠ص</td>
                      <td className="contact-info-desc-ar">الاحد</td>
                    </tr>
                    <tr>
                      <td className="contact-info-desc-ar">٦:٠٠م</td>
                      <td className="contact-info-desc-ar">٨:٠٠ص</td>
                      <td className="contact-info-desc-ar">الاثنين</td>
                    </tr>
                    <tr>
                      <td className="contact-info-desc-ar">٦:٠٠م</td>
                      <td className="contact-info-desc-ar">٨:٠٠ص</td>
                      <td className="contact-info-desc-ar">الثلاثاء</td>
                    </tr>
                    <tr>
                      <td className="contact-info-desc-ar">٦:٠٠م</td>
                      <td className="contact-info-desc-ar">٨:٠٠ص</td>
                      <td className="contact-info-desc-ar">الاربعاء</td>
                    </tr>
                    <tr>
                      <td className="contact-info-desc-ar">٢:٠٠م</td>
                      <td className="contact-info-desc-ar">٨:٠٠ص</td>
                      <td className="contact-info-desc-ar">الخميس</td>
                    </tr>
                  </table>
                </Card>
              </div>
            </div>
          </div>
        </div>
        <div className="map-containter">
          <Map
            className="map-style"
            google={this.props.google}
            zoom={14}
            initialCenter={{ lat: 31.936613, lng: 35.9231185 }}
            style={{ height: "400px" }}
          >
            <Marker
              onClick={this.onMarkerClick}
              name={"Current location"}
              position={{ lat: 31.936613, lng: 35.9231185 }}
            />
            <InfoWindow onClose={this.onInfoWindowClose}></InfoWindow>
          </Map>
        </div>
        <FooterAr />
      </Fragment>
    );
  }
}

export default withStyles(styles)(
  GoogleApiWrapper({
    apiKey: "AIzaSyDsRyZUloz5OVPmjHk5qN7OMOxCEu-yol4",
  })(ContactusAr)
);

/**
 *                    <p
                  className="contact-info-desc-ar"
                  style={{ fontWeight: "1000", margin: "5px" }}
                >
                  سبت
                  <span style={{ fontWeight: "1000" }}>:</span> ٨
                  <span style={{ fontWeight: "1000" }}>:</span>٠٠ص - ٦
                  <span style={{ fontWeight: "1000" }}>:</span>٠٠م
                </p>

                <p
                  className="contact-info-desc-ar"
                  style={{ fontWeight: "1000", margin: "5px" }}
                >
                  احد
                  <span style={{ fontWeight: "1000" }}>:</span> ٨
                  <span style={{ fontWeight: "1000" }}>:</span>٠٠ص - ٦
                  <span style={{ fontWeight: "1000" }}>:</span>٠٠م
                </p>
                <p
                  className="contact-info-desc-ar"
                  style={{ fontWeight: "1000", margin: "5px" }}
                >
                  اثنين<span style={{ fontWeight: "1000" }}>:</span> ٨
                  <span style={{ fontWeight: "1000" }}>:</span>٠٠ص - ٦
                  <span style={{ fontWeight: "1000" }}>:</span>٠٠م
                </p>
                <p
                  className="contact-info-desc-ar"
                  style={{ fontWeight: "1000", margin: "5px" }}
                >
                  ثلاثاء<span style={{ fontWeight: "1000" }}>:</span> ٨
                  <span style={{ fontWeight: "1000" }}>:</span>٠٠ص - ٦
                  <span style={{ fontWeight: "1000" }}>:</span>٠٠م
                </p>
                <p
                  className="contact-info-desc-ar"
                  style={{ fontWeight: "1000", margin: "5px" }}
                >
                  اربعاء<span style={{ fontWeight: "1000" }}>:</span> ٨
                  <span style={{ fontWeight: "1000" }}>:</span>٠٠ص - ٦
                  <span style={{ fontWeight: "1000" }}>:</span>٠٠م
                </p>
                <p
                  className="contact-info-desc-ar"
                  style={{ fontWeight: "1000", margin: "5px" }}
                >
                  خميس<span style={{ fontWeight: "1000" }}>:</span> ٨
                  <span style={{ fontWeight: "1000" }}>:</span>٠٠ص - ٢
                  <span style={{ fontWeight: "1000" }}>:</span>٠٠م
                </p>
 */
