import React, { Component, Fragment } from "react";
import "./homepage.css";
import { Slide } from "react-slideshow-image";
import { Zoom } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

import Image1 from "../../../assets/carousel-imgs/p1.png";
import Image2 from "../../../assets/carousel-imgs/p11.jpg";
import Image3 from "../../../assets/carousel-imgs/p3.jpg";
import Image4 from "../../../assets/carousel-imgs/p4.jpg";
//import Image5 from "../../../assets/carousel-imgs/p11.jpg";

import character from "../../../assets/character.png";
import clients from "../../../assets/clients.png";

import HeaderEng from "../../../components/EnglishComponents/header-eng/header";
import FooterEng from "../../../components/EnglishComponents/footer-eng/footer";

export class homepage extends Component {
  render() {
    return (
      <Fragment>
        <HeaderEng handleClick={this.props.handleClick} />

        <div>
          <Slide style={{ objectFit: "cover" }}>
            <div className="each-slide" style={{ objectFit: "cover" }}>
              <div
                className="slideContainer"
                style={{
                  backgroundImage: `url(${Image1}) `,
                  backgroundSize: "cover",
                  objectFit: "cover",
                }}
              ></div>
            </div>
            <div className="each-slide" style={{ objectFit: "cover" }}>
              <div
                className="slideContainer"
                style={{
                  backgroundImage: `url(${Image2}) `,
                  backgroundSize: "cover",
                  overflow: "hidden",
                }}
              ></div>
            </div>
            <div className="each-slide" style={{ objectFit: "cover" }}>
              <div
                className="slideContainer"
                style={{
                  backgroundImage: `url(${Image3}) `,
                  backgroundSize: "cover",
                  overflow: "hidden",
                }}
              ></div>
            </div>
            <div className="each-slide" style={{ objectFit: "cover" }}>
              <div
                className="slideContainer"
                style={{
                  backgroundImage: `url(${Image4}) `,
                  backgroundSize: "cover",
                  overflow: "hidden",
                }}
              ></div>
            </div>
          </Slide>
          <div className="clients-title"></div>
          <div className="info-paragraph-box">
            <div className="paragraph-container">
              <div className="clients-title-text-1">Our Story</div>
              <p className="introduction-paragraph">
                Al-Zahra’a Printing Press was founded in 1959. It was
                established by the late Ali Youssef Othman Shawer in Jerusalem,
                Palestine. Later, it was relocated to Amman, Jordan
              </p>
              <p className="introduction-paragraph">
                Since its establishment, Al-Zahra’a has grown and evolved into
                one of the leading companies in the industry, and one of the
                most recognizable names of the last half century. This was done
                by focusing on attracting all of the largest clients in the
                field, and providing them with an expansive range of highly
                advanced printing solutions
              </p>
            </div>
            <div className="intro-img-container">
              <img
                src={character}
                alt="character"
                className="info-character-img"
              />
            </div>
          </div>
          <div className="clients-title">
            <div className="clients-title-text-2"> Our Clients</div>
          </div>
          <img src={clients} alt="clients" className="clients-img" />
        </div>
        <FooterEng />
      </Fragment>
    );
  }
}

export default homepage;

/**
 * The
                late Ali Shawer was the father and the founder of Al-Zahra’a,
                and remains to this day one of the biggest names in the printing
                industry.

                In
                1978, the press opened its first branch in Amman, Jordan.
 */
