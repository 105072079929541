import React, { Component, Fragment } from "react";
//import "../../EnglishPages/homepage-eng/homepage.css";
import "./homepage-ar.css";

import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

import Image1 from "../../../assets/carousel-imgs/p1.png";
import Image2 from "../../../assets/carousel-imgs/p11.jpg";
import Image3 from "../../../assets/carousel-imgs/p3.jpg";
import Image4 from "../../../assets/carousel-imgs/p4.jpg";
//import Image5 from "../../../assets/carousel-imgs/p11.jpg";

import character from "../../../assets/character.png";
import clients from "../../../assets/clients.png";

import HeaderAr from "../../../components/ArabicComponents/header-eng/header-ar";
import FooterAr from "../../../components/ArabicComponents/footer-ar/footer-ar";

export class homepage extends Component {
  render() {
    return (
      <Fragment>
        <HeaderAr handleClick={this.props.handleClick} />

        <div>
          <Slide style={{ objectFit: "cover" }}>
            <div className="each-slide" style={{ objectFit: "cover" }}>
              <div
                className="slideContainer"
                style={{
                  backgroundImage: `url(${Image1})`,
                  backgroundSize: "cover",
                  objectFit: "cover",
                }}
              ></div>
            </div>
            <div className="each-slide" style={{ objectFit: "cover" }}>
              <div
                className="slideContainer"
                style={{
                  backgroundImage: `url(${Image2})`,
                  backgroundSize: "cover",
                }}
              ></div>
            </div>
            <div className="each-slide" style={{ objectFit: "cover" }}>
              <div
                className="slideContainer"
                style={{
                  backgroundImage: `url(${Image3})`,
                  backgroundSize: "cover",
                }}
              ></div>
            </div>
            <div className="each-slide" style={{ objectFit: "cover" }}>
              <div
                className="slideContainer"
                style={{
                  backgroundImage: `url(${Image4})`,
                  backgroundSize: "cover",
                }}
              ></div>
            </div>
          </Slide>

          <div className="clients-title"></div>
          <div className="info-paragraph-box">
            <div className="paragraph-container">
              <div
                className="clients-title-text-1"
                style={{ letterSpacing: "0", textAlign: "right" }}
              >
                قصتنا
              </div>
              <p
                className="introduction-paragraph"
                style={{ writingMode: "sideways-rl", textAlign: "right" }}
              >
                تأسست مطبعة الزهراء عام 1959 على يد المرحوم علي يوسف احمد عثمان
                شاور في القدس, فلسطين. وانتقلت الى عمان, الاردن
              </p>
              <p
                className="introduction-paragraph"
                style={{ writingMode: "sideways-rl", textAlign: "right" }}
              >
                منذ نشأتها، نمت مطبعة الزهراء وتطورت لتصبح واحدة من الشركات
                الرائدة في هذا المجال، واِحدى أكثر الأسماء شهرةً. أنجزت ذلك من
                خلال التركيز على كافة عملائها، وتزويدهم بمجموعة واسعة من حلول
                الطباعة المتقدمة
              </p>
            </div>

            <div className="intro-img-container">
              <img
                src={character}
                alt="character"
                className="info-character-img"
              />
            </div>
          </div>
          <div className="clients-title">
            <div
              className="clients-title-text-2"
              style={{ letterSpacing: "0" }}
            >
              زبائننا
            </div>
          </div>
          <img src={clients} alt="clients" className="clients-img" />
        </div>
        <FooterAr />
      </Fragment>
    );
  }
}

export default homepage;

/** 
<div className="each-slide" style={{ objectFit: "cover" }}>
              <div
                className="slideContainer"
                style={{
                  backgroundImage: `url(${Image5})`,
                  backgroundSize: "cover",
                }}
              ></div>
            </div>







          <Slide style={{ objectFit: "cover" }}>
            <div className="each-slide" style={{ height: "500px" }}>
              <div
                className="slideContainer"
                style={{
                  backgroundImage: `url(${Image1})`,
                  backgroundSize: "cover",
                  height: "500px",
                }}
              ></div>
            </div>
            <div className="each-slide" style={{ height: "500px" }}>
              <div
                className="slideContainer"
                style={{
                  backgroundImage: `url(${Image2})`,
                  backgroundSize: "cover",
                  height: "500px",
                }}
              ></div>
            </div>
            <div className="each-slide" style={{ height: "500px" }}>
              <div
                className="slideContainer"
                style={{
                  backgroundImage: `url(${Image3})`,
                  backgroundSize: "cover",
                  height: "500px",
                }}
              ></div>
            </div>
            <div className="each-slide" style={{ height: "500px" }}>
              <div
                className="slideContainer"
                style={{
                  backgroundImage: `url(${Image4})`,
                  backgroundSize: "cover",
                  height: "500px",
                }}
              ></div>
            </div>
            <div className="each-slide" style={{ height: "500px" }}>
              <div
                className="slideContainer"
                style={{
                  backgroundImage: `url(${Image5})`,
                  backgroundSize: "cover",
                  height: "500px",
                }}
              ></div>
            </div>
          </Slide>
*/
