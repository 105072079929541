import React, { Component, Fragment } from "react";
//import "../../EnglishPages/aboutus-eng/aboutus-eng.css";
import "./aboutus-ar.css";

import HeaderAr from "../../../components/ArabicComponents/header-eng/header-ar";
import FooterAr from "../../../components/ArabicComponents/footer-ar/footer-ar";

export class AboutUs extends Component {
  render() {
    return (
      <Fragment>
        <HeaderAr handleClick={this.props.handleClick} />
        <div>
          <div>
            <div className="InfoBlockDeskCard1">
              <div
                className="InfoBlockDeskImageBtn"
                style={{ objectFit: "cover" }}
              >
                من نحن
              </div>
            </div>

            <div className="about-us-row">
              <div className="about-us-text-block">
                <h3
                  className="about-us-text-block-title"
                  style={{ letterSpacing: "0", textAlign: "right" }}
                >
                  القدرة الإنتاجية
                </h3>
                <p
                  className="about-us-text-block-desc"
                  style={{ textAlign: "right" }}
                >
                  تبلغ مساحة العمل لدينا 3000 متر مربع، وهي مجهزة بأحدث الآلات
                  التي تساعد على تقديم أفضل الخدمات لعملائنا
                </p>
              </div>

              <div className="about-us-img-block aboutus-img1"></div>
            </div>
          </div>

          <div className="about-us-row">
            <div className="about-us-img-block aboutus-img2"></div>

            <div className="about-us-text-block">
              <h3
                className="about-us-text-block-title"
                style={{ letterSpacing: "0", textAlign: "right" }}
              >
                فريقنا
              </h3>
              <p
                className="about-us-text-block-desc"
                style={{ textAlign: "right" }}
              >
                يتكون فريقنا من موظفون مؤهلون تأهيلاً كاملاً لتأدية كل من
                الوظائف المتاحة لدينا
              </p>
            </div>
          </div>

          <div className="about-us-row">
            <div className="about-us-text-block">
              <h3
                className="about-us-text-block-title"
                style={{ letterSpacing: "0", textAlign: "right" }}
              >
                مهمتنا
              </h3>
              <p
                className="about-us-text-block-desc"
                style={{ textAlign: "right" }}
              >
                مهمتنا هي منح عملائنا أفضل تجربة في أعمال الطباعة على درجه عاليه
                من الرضا
              </p>
            </div>

            <div className="about-us-img-block  aboutus-img3"></div>
          </div>

          <div className="about-us-row">
            <div className="about-us-img-block  aboutus-img4"></div>

            <div className="about-us-text-block">
              <h3
                className="about-us-text-block-title"
                style={{ letterSpacing: "0", textAlign: "right" }}
              >
                رؤيتنا
              </h3>
              <p
                className="about-us-text-block-desc"
                style={{ textAlign: "right" }}
              >
                قياده المنطقة في تقديم حلول طباعة احترافية عالية الجودة والتطور
                الدائم و المواكبة مع المستوى التكنولوجي
              </p>
            </div>
          </div>

          <div className="about-us-row">
            <div className="about-us-text-block">
              <h3
                className="about-us-text-block-title"
                style={{ letterSpacing: "0", textAlign: "right" }}
              >
                القيم الأساسية لدينا
              </h3>

              <p
                style={{
                  marginBottom: "5px",
                  fontWeight: "300",
                  letterSpacing: "0",
                  textAlign: "right",
                  direction: "rtl",
                }}
                className="about-us-text-block-desc"
              >
                ١- بناء العلاقات مع العملاء على أساس النزاهة والثقة والاحترام
              </p>

              <p
                style={{
                  marginBottom: "5px",
                  fontWeight: "300",
                  letterSpacing: "0",
                  textAlign: "right",
                  direction: "rtl",
                }}
                className="about-us-text-block-desc"
              >
                ٢- الحفاظ على الاحترافية بالتعامل
              </p>
              <p
                style={{
                  marginBottom: "5px",
                  fontWeight: "300",
                  letterSpacing: "0",
                  textAlign: "right",
                  direction: "rtl",
                }}
                className="about-us-text-block-desc"
              >
                ٣-الالتزام بالمواعيد، والكفاءة في كل مهمة ننجزها
              </p>
              <p
                style={{
                  marginBottom: "10px",
                  fontWeight: "300",
                  letterSpacing: "0",
                  textAlign: "right",
                  direction: "rtl",
                }}
                className="about-us-text-block-desc"
              >
                ٤- التمسك بمعايير عالية لإرضاء عملائنا
              </p>
            </div>

            <div className="about-us-img-block  aboutus-img5"></div>
          </div>
        </div>
        <FooterAr />
      </Fragment>
    );
  }
}

export default AboutUs;
