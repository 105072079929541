import React, { Component, Fragment } from "react";
import "./products-eng.css";
import Popup from "../../../components/popup/popup";
import HeaderEng from "../../../components/EnglishComponents/header-eng/header";
import FooterEng from "../../../components/EnglishComponents/footer-eng/footer";

//IMAGES

import productheader from "../../../assets/carousel-imgs/p14.png";

import textbooks from "../../../assets/product-imgs/textbook-magazines.jpg";
import calendar from "../../../assets/product-imgs/calendar.jpg";
import securityimg from "../../../assets/product-imgs/security-printing.jpg";
import paperbags from "../../../assets/product-imgs/paper-bags.jpg";

import brochures from "../../../assets/product-imgs/brochures.jpg";
import cartonboxes from "../../../assets/product-imgs/carton-packages.png";
import cartonholders from "../../../assets/product-imgs/carton-holder.png";
import paperplates from "../../../assets/product-imgs/paper-plates.png";

import wallimg from "../../../assets/product-imgs/wall-danglers.png";
import cases from "../../../assets/product-imgs/folders.png";
import idprod from "../../../assets/product-imgs/letterhead.jpg";
import commercialprints from "../../../assets/product-imgs/commercial-prints.png";

export class ProductEng extends Component {
  state = {
    popupopen: false,
    imgProd: "",
  };

  onClosingPopUp = () => {
    this.setState({ popupopen: false });
  };

  render() {
    const popup =
      this.state.popupopen === null ? null : (
        <Popup
          uibool={this.state.popupopen}
          onClosingPopUp={this.onClosingPopUp}
          imgProd={this.state.imgProd}
        />
      );

    return (
      <Fragment>
        <HeaderEng handleClick={this.props.handleClick} />
        <div>
          {popup}
          <img
            src={productheader}
            alt="products-header"
            className="productheader"
          />

          <div
            style={{
              alignItems: "center",
              width: "100%",
              marginBottom: "50px",
            }}
          >
            <div class="three-columns-products">
              <div class="products-columns">
                <div class="product-wrapper">
                  <div
                    class="product-button "
                    onClick={() =>
                      this.setState({ popupopen: true, imgProd: "textbooks" })
                    }
                  >
                    Textbooks, Magazines, Notebooks
                  </div>
                </div>
                <div class="product-wrapper">
                  <div
                    class="product-button"
                    onClick={() =>
                      this.setState({ popupopen: true, imgProd: "calendar" })
                    }
                  >
                    Wall Calendar, Office Calendar
                  </div>
                </div>

                <div class="product-wrapper">
                  <div
                    class="product-button "
                    onClick={() =>
                      this.setState({ popupopen: true, imgProd: "paperbags" })
                    }
                  >
                    Paper Bags
                  </div>
                </div>
              </div>
              <div class="products-columns">
                <div class="product-wrapper">
                  <div
                    class="product-button "
                    onClick={() =>
                      this.setState({ popupopen: true, imgProd: "brochures" })
                    }
                    ImgProd={brochures}
                  >
                    Brochures
                  </div>
                </div>
                <div class="product-wrapper">
                  <div
                    class="product-button "
                    onClick={() =>
                      this.setState({ popupopen: true, imgProd: "cartonboxes" })
                    }
                    ImgProd={cartonboxes}
                  >
                    Cardboard Packages
                  </div>
                </div>
                <div class="product-wrapper">
                  <div
                    class="product-button"
                    onClick={() =>
                      this.setState({
                        popupopen: true,
                        imgProd: "cartonholders",
                      })
                    }
                    ImgProd={cartonholders}
                  >
                    Cup Holders
                  </div>
                </div>
                <div class="product-wrapper">
                  <div
                    class="product-button"
                    onClick={() =>
                      this.setState({ popupopen: true, imgProd: "paperplates" })
                    }
                    ImgProd={paperplates}
                  >
                    Paper Plates
                  </div>
                </div>
              </div>
              <div class="products-columns">
                <div class="product-wrapper">
                  <div
                    class="product-button"
                    onClick={() =>
                      this.setState({ popupopen: true, imgProd: "wallimg" })
                    }
                    ImgProd={wallimg}
                  >
                    Wall Hangings
                  </div>
                </div>
                <div class="product-wrapper">
                  <div
                    class="product-button"
                    onClick={() =>
                      this.setState({ popupopen: true, imgProd: "cases" })
                    }
                    ImgProd={cases}
                  >
                    Cases (cd covers, folders)
                  </div>
                </div>
                <div class="product-wrapper">
                  <div
                    class="product-button"
                    onClick={() =>
                      this.setState({ popupopen: true, imgProd: "idprod" })
                    }
                    ImgProd={idprod}
                  >
                    Identification Products (Visa Card, Letterhead)
                  </div>
                </div>
                <div class="product-wrapper">
                  <div
                    class="product-button"
                    onClick={() =>
                      this.setState({ popupopen: true, imgProd: "businessnb" })
                    }
                    ImgProd={commercialprints}
                  >
                    Commercial Prints
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FooterEng />
      </Fragment>
    );
  }
}

export default ProductEng;
