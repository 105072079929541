import React, { Component, Fragment } from "react";
import "../../EnglishPages/products-eng/products-eng.css";
import Popup from "../../../components/popup/popup";
import HeaderAr from "../../../components/ArabicComponents/header-eng/header-ar";
import FooterAr from "../../../components/ArabicComponents/footer-ar/footer-ar";

//IMAGES
import productheader from "../../../assets/carousel-imgs/p14.png";
/** 
import textbooks from "../../../assets/product-imgs/textbook-magazines.jpg";
import calendar from "../../../assets/product-imgs/calendar.jpg";
import securityimg from "../../../assets/product-imgs/security-printing.jpg";
import paperbags from "../../../assets/product-imgs/paper-bags.jpg";
*/
import brochures from "../../../assets/product-imgs/brochures.jpg";
import cartonboxes from "../../../assets/product-imgs/carton-packages.png";
import cartonholders from "../../../assets/product-imgs/carton-holder.png";
import paperplates from "../../../assets/product-imgs/paper-plates.png";

import wallimg from "../../../assets/product-imgs/wall-danglers.png";
import cases from "../../../assets/product-imgs/folders.png";
import idprod from "../../../assets/product-imgs/letterhead.jpg";
import commercialprints from "../../../assets/product-imgs/commercial-prints.png";

export class ProductEng extends Component {
  state = {
    popupopen: false,
    imgProd: "",
  };

  onClosingPopUp = () => {
    this.setState({ popupopen: false });
  };

  render() {
    const popup =
      this.state.popupopen === null ? null : (
        <Popup
          uibool={this.state.popupopen}
          onClosingPopUp={this.onClosingPopUp}
          imgProd={this.state.imgProd}
        />
      );

    return (
      <Fragment>
        <HeaderAr handleClick={this.props.handleClick} />
        <div>
          {popup}
          <img
            src={productheader}
            alt="products-header"
            className="productheader"
          />
          <div
            style={{
              alignItems: "center",
              width: "100%",
              marginBottom: "100px",
            }}
          >
            <div class="three-columns-products">
              <div class="products-columns">
                <div class="product-wrapper">
                  <div
                    class="product-button "
                    onClick={() =>
                      this.setState({ popupopen: true, imgProd: "textbooks" })
                    }
                  >
                    المجلات , دفاتر الملاحظات
                  </div>
                </div>
                <div class="product-wrapper">
                  <div
                    class="product-button"
                    onClick={() =>
                      this.setState({ popupopen: true, imgProd: "calendar" })
                    }
                  >
                    تقويم الحائط , تقويم المكتب
                  </div>
                </div>

                <div class="product-wrapper">
                  <div
                    class="product-button "
                    onClick={() =>
                      this.setState({ popupopen: true, imgProd: "paperbags" })
                    }
                  >
                    الاكياس الورقية
                  </div>
                </div>
              </div>
              <div class="products-columns">
                <div class="product-wrapper">
                  <div
                    class="product-button "
                    onClick={() =>
                      this.setState({ popupopen: true, imgProd: "brochures" })
                    }
                    ImgProd={brochures}
                  >
                    المنشورات الدعائية
                  </div>
                </div>
                <div class="product-wrapper">
                  <div
                    class="product-button "
                    onClick={() =>
                      this.setState({ popupopen: true, imgProd: "cartonboxes" })
                    }
                    ImgProd={cartonboxes}
                  >
                    العلب الكرتونية
                  </div>
                </div>
                <div class="product-wrapper">
                  <div
                    class="product-button"
                    onClick={() =>
                      this.setState({
                        popupopen: true,
                        imgProd: "cartonholders",
                      })
                    }
                    ImgProd={cartonholders}
                  >
                    الحاملات الكرتونية
                  </div>
                </div>
                <div class="product-wrapper">
                  <div
                    class="product-button"
                    onClick={() =>
                      this.setState({ popupopen: true, imgProd: "paperplates" })
                    }
                    ImgProd={paperplates}
                  >
                    الاصحن الورقية
                  </div>
                </div>
              </div>
              <div class="products-columns">
                <div class="product-wrapper">
                  <div
                    class="product-button"
                    onClick={() =>
                      this.setState({ popupopen: true, imgProd: "wallimg" })
                    }
                    ImgProd={wallimg}
                  >
                    معلقات جدارية
                  </div>
                </div>
                <div class="product-wrapper">
                  <div
                    class="product-button"
                    onClick={() =>
                      this.setState({ popupopen: true, imgProd: "cases" })
                    }
                    ImgProd={cases}
                  >
                    الحافظات (كفر سي دي , فولدرات )
                  </div>
                </div>
                <div class="product-wrapper">
                  <div
                    class="product-button"
                    onClick={() =>
                      this.setState({ popupopen: true, imgProd: "idprod" })
                    }
                    ImgProd={idprod}
                  >
                    المنتجات التعريفية ( فيزت كارت , ورق المروس )
                  </div>
                </div>
                <div class="product-wrapper">
                  <div
                    class="product-button"
                    onClick={() =>
                      this.setState({ popupopen: true, imgProd: "businessnb" })
                    }
                    ImgProd={commercialprints}
                  >
                    الدفاتر التجارية
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FooterAr />
      </Fragment>
    );
  }
}

export default ProductEng;
