import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
//BrowserRouter as Router
//English
import HomePageEng from "../pages/EnglishPages/homepage-eng/homepage";
import AboutUsEng from "../pages/EnglishPages/aboutus-eng/aboutus-eng";
import ProductEng from "../pages/EnglishPages/products-eng/products-eng";
import ContactUsEng from "../pages/EnglishPages/contactus-eng/contactus-eng";
//ARABIC
import HomePageAr from "../pages/ArabicPages/homepage-ar/homepage-ar";
import AboutUsAr from "../pages/ArabicPages/aboutus-ar/aboutus-ar";
import ProductAr from "../pages/ArabicPages/products-ar/products-ar";
import ContactUsAr from "../pages/ArabicPages/contactus-ar/contactus-ar";

export class RouterPage extends Component {
  render() {
    return (
      <Switch>
        <Route exact path="/ar">
          <HomePageAr handleClick={this.props.handleClick} />
        </Route>
        <Route path="/ar/about-us">
          <AboutUsAr handleClick={this.props.handleClick} />
        </Route>
        <Route path="/ar/products">
          <ProductAr handleClick={this.props.handleClick} />
        </Route>
        <Route path="/ar/contact-us">
          <ContactUsAr handleClick={this.props.handleClick} />
        </Route>
        <Route exact path="/">
          <HomePageEng handleClick={this.props.handleClick} />
        </Route>
        <Route path="/en/about-us">
          <AboutUsEng handleClick={this.props.handleClick} />
        </Route>
        <Route path="/en/products">
          <ProductEng handleClick={this.props.handleClick} />
        </Route>
        <Route path="/en/contact-us">
          <ContactUsEng handleClick={this.props.handleClick} />
        </Route>
      </Switch>
    );
  }
}

export default RouterPage;

/**
 * <Router>
                <Route exact path="/">
          <HomePageEng handleClick={this.props.handleClick} />
        </Route>
        <Route path="/en/about-us">
          <AboutUsEng handleClick={this.props.handleClick} />
        </Route>
        <Route path="/en/products">
          <ProductEng handleClick={this.props.handleClick} />
        </Route>
        <Route path="/en/contact-us" component={ContactUsEng}>
          <ContactUsEng handleClick={this.props.handleClick} />
        </Route>
      </Router>
 */
