import { Component } from "react";
import "./App.css";

import Main from "./pages/main";
import { BrowserRouter as Router } from "react-router-dom";

//MUI
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import themeFile from "./util/theme";

const theme = createMuiTheme(themeFile);

class App extends Component {
  state = {
    setOpen: false,
  };

  handleClick = (bool) => {
    this.setState({ setOpen: bool });
  };
  render() {
    return (
      <Router>
        <MuiThemeProvider theme={theme}>
          <div className="App">
            <Main />
          </div>
        </MuiThemeProvider>
      </Router>
    );
  }
}

export default App;

/**
 *
 */
