import React, { Component } from "react";
//import "../../EnglishComponents/header-eng/header.css";
import "./header-ar.css";
import DeskNav from "./desknav-ar/desknav-ar";
import logo from "../../../assets/logo-png.png";
import MenuIcon from "@material-ui/icons/Menu";
import { Link } from "react-router-dom";
import arabic from "../../../assets/flags/arab.png";
import english from "../../../assets/flags/english.png";

export class Header extends Component {
  onClickClose = () => {
    this.props.handleClick(true);
  };

  CurrentRouteEn = () => {
    console.log("en");
    var currentLocation = window.location.pathname;

    if (currentLocation === "/ar/" || currentLocation === "/ar") {
      /** 
      const path = currentLocation.split("/");
      path[1] = "";
      const newPath = path.join("/");
      console.log(newPath);
*/
      return (window.location.pathname = "/");
    } else if (currentLocation.split("/")[1] === "ar") {
      const path = currentLocation.split("/");
      path[1] = "en";
      const newPath = path.join("/");
      return (window.location.pathname = newPath);
    }
  };
  //<img src={arabic} alt="arabic" className="language-img" />
  render() {
    return (
      <div className="Header">
        <div className="language-box-ar">
          <span className="language-btn" onClick={this.CurrentRouteEn}>
            English
          </span>
        </div>
        <Link to="/ar">
          <img src={logo} className="webLogo-ar" alt="website-logo" />
        </Link>

        <div className="menuMobileDisplay-ar">
          <MenuIcon className="menuLogo-ar" onClick={this.onClickClose} />
        </div>
        <div className="menuDeskDisplay">
          <DeskNav />
        </div>
      </div>
    );
  }
}

export default Header;
