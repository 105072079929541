import React, { Component } from "react";
import "./mobilenav-ar.css";
import MenuItem from "@material-ui/core/MenuItem";
import HomeIcon from "@material-ui/icons/Home";
import StorefrontIcon from "@material-ui/icons/Storefront";
import InfoIcon from "@material-ui/icons/Info";
import PermContactCalendarIcon from "@material-ui/icons/PermContactCalendar";

import { Link } from "react-router-dom";

import webLogo from "../../../../assets/logo-png.png";

class MenuBar extends Component {
  onClickClose = () => {
    this.props.handleClick(false);
  };
  render() {
    const { menuAnimation } = this.props;
    //component={Link}
    return (
      <div
        className={`${menuAnimation}`}
        style={{
          animationFillMode: "forwards",
          backgroundColor: "hsl(204, 100%, 64%)",
          zIndex: "2200",
          position: "fixed",
        }}
      >
        <div className="mobile-menu-logo-container">
          <img
            src={webLogo}
            alt="website-logo"
            className="mobile-menu-container"
          />
        </div>
        <MenuItem component={Link} to="/ar" onClick={this.onClickClose}>
          <HomeIcon className="mobile-menu-icons" />
          <div className="buttonText">الرئيسية</div>
        </MenuItem>

        <MenuItem
          component={Link}
          to="/ar/products"
          onClick={this.onClickClose}
        >
          <StorefrontIcon className="mobile-menu-icons" />
          <div className="buttonText">منتجاتنا</div>
        </MenuItem>
        <MenuItem
          component={Link}
          to="/ar/about-us"
          onClick={this.onClickClose}
        >
          <InfoIcon className="mobile-menu-icons" />
          <div className="buttonText">من نحن</div>
        </MenuItem>
        <MenuItem
          component={Link}
          to="/ar/contact-us"
          onClick={this.onClickClose}
        >
          <PermContactCalendarIcon className="mobile-menu-icons" />
          <div className="buttonText">إتصل بنا</div>
        </MenuItem>
      </div>
    );
  }
}
/**className={this.props.animationMenu} */
export default MenuBar;
