import React, { Component } from "react";
import "./header.css";
import DeskNav from "./desknav/desknav";
import logo from "../../../assets/Logo-png-english.png";
import MenuIcon from "@material-ui/icons/Menu";

import { Link } from "react-router-dom";

export class Header extends Component {
  onClickClose = () => {
    this.props.handleClick(true);
  };

  CurrentRouteAr = () => {
    console.log("ar");
    var currentLocation = window.location.pathname;

    if (currentLocation === "/") {
      return (window.location.pathname = "/ar");
    } else if (currentLocation.split("/")[1] === "en") {
      const path = currentLocation.split("/");
      path[1] = "ar";
      const newPath = path.join("/");
      return (window.location.pathname = newPath);
    }
  };
  //<img src={english} alt="english" className="language-img" />
  render() {
    return (
      <div className="Header">
        <div className="language-box">
          <span className="language-btn" onClick={this.CurrentRouteAr}>
            Arabic
          </span>
        </div>
        <Link to="/">
          <img src={logo} className="webLogo" alt="website-logo" />
        </Link>
        <div className="menuMobileDisplay">
          <MenuIcon className="menuLogo" onClick={this.onClickClose} />
        </div>
        <div className="menuDeskDisplay">
          <DeskNav />
        </div>
      </div>
    );
  }
}

export default Header;
