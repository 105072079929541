import React, { Component } from "react";
import { Link } from "react-router-dom";
//import "../../../EnglishComponents/header-eng/desknav/desknav.css";
import "./desknav-ar.css";
//AboutUsEng
export class desknav extends Component {
  render() {
    return (
      <div className="desknav-ar">
        <Link to="/ar/contact-us" className="nav-btn" id="contact">
          <span className="nav-btn-text">إتصل بنا</span>
        </Link>

        <Link to="/ar/about-us" className="nav-btn" id="about">
          <span className="nav-btn-text">من نحن</span>
        </Link>
        <Link to="/ar/products" className="nav-btn" id="products">
          <span className="nav-btn-text">منتجاتنا</span>
        </Link>
        <Link to="/ar" className="nav-btn" id="home">
          <span className="nav-btn-text">الرئيسية</span>
        </Link>
      </div>
    );
  }
}

export default desknav;
