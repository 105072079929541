import React, { Component, Fragment } from "react";
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Link } from "react-router-dom";
import HeaderEng from "../../../components/EnglishComponents/header-eng/header";
import FooterEng from "../../../components/EnglishComponents/footer-eng/footer";
import axios from "axios";

import PhoneIcon from "@material-ui/icons/Phone";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import AllInboxIcon from "@material-ui/icons/AllInbox";
import PrintIcon from "@material-ui/icons/Print";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";

import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import emailjs from "emailjs-com";

import "./contactus-eng.css";

export class ContactusEng extends Component {
  state = {
    name: "",
    email: "",
    message: "",
    phone: "",
    errors: {},
    loading: false,
    form: false,
  };

  onFormSubmit = async (event) => {
    event.preventDefault();
    this.setState({ errors: {}, loading: true });
    const data = {
      name: this.state.name,
      email: this.state.email,
      phone: this.state.phone,
      message: this.state.message,
    };

    emailjs
      .send("service_spwwr54", "template_6pgkh9p", data, "V5q4iP4CPtUDxaap_")
      .then(
        (result) => {
          console.log(result.text);
          this.setState({ errors: {}, loading: false, form: true });
          // this.setState({ form: true });
          // alert("Message Sent Successfully");
          // window.location.reload(false);
          // alert("Message Sent");
        },
        (error) => {
          console.log(error.text);
          this.setState({ form: false, loading: false });
          alert("Message Not Sent");
        }
      );

    // await axios
    //   .post("/email", data)
    //   .then((res) => {
    //     console.log("sent");
    //     this.setState({ errors: {}, loading: false, form: true });
    //   })
    //   .catch((err) => {
    //     console.log("not");
    //     this.setState({ errors: err.response.data, loading: false });
    //   });
  };

  changeHandler = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleParagraphChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value.split("\\n").join("\n"),
    });
  };

  render() {
    const { errors } = this.state;

    const contactform =
      this.state.form === false ? (
        <Fragment>
          <form noValidate onSubmit={this.onFormSubmit}>
            <TextField
              className="Contact-Form-Input"
              id="standard-secondary"
              label="Name"
              color="primary"
              name="name"
              type="text"
              variant="filled"
              helperText={errors.name}
              onChange={this.changeHandler}
              error={errors.name ? true : false}
              required
            />
            <TextField
              className="Contact-Form-Input"
              id="standard-secondary"
              label="E-Mail"
              color="primary"
              name="email"
              type="email"
              variant="filled"
              helperText={errors.email}
              onChange={this.changeHandler}
              error={errors.email ? true : false}
              required
            />
            <TextField
              className="Contact-Form-Input"
              id="standard-secondary"
              label="Phone Number"
              color="primary"
              name="phone"
              type="text"
              variant="filled"
              onChange={this.changeHandler}
            />
            <TextField
              className="Contact-Form-Input"
              id="standard-secondary"
              label="Message"
              color="primary"
              name="message"
              type="text"
              helperText={errors.message}
              onChange={this.handleParagraphChange}
              error={errors.message ? true : false}
              variant="filled"
              multiline
              rows={4}
              required
            />
            <Button
              variant="contained"
              color="primary"
              type="submit"
              className="ContactFormBtn"
            >
              <span className="ContactFormBtnTitle">Submit</span>
              {this.state.loading && <CircularProgress size={30} />}
            </Button>
          </form>
        </Fragment>
      ) : (
        <div
          className="Enquiry-Form-Box-top"
          style={{ textAlign: "center", margin: "75px 0" }}
        >
          <h1 className="EnquiryFormTitle">Successfully Sent</h1>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            className="EnquiryFormBtn"
            component={Link}
            to="/"
          >
            <span className="EnquiryFormBtnTitle">Return Home</span>
          </Button>
        </div>
      );
    return (
      <Fragment>
        <HeaderEng handleClick={this.props.handleClick} />

        <div
          className="ContactFormBackground"
          style={{ height: "100%", width: "100%" }}
        >
          <div className="Enquiry-Form-Box-top" style={{ textAlign: "center" }}>
            <span className="ContactFormTitle">Contact Us</span>
          </div>

          <div className="contact-1-row">
            <div className="contact-info-box" style={{ marginRight: "3%" }}>
              <div>
                <h3 className="contact-info-title">Location</h3>
                <p className="contact-info-desc" style={{ textAlign: "left" }}>
                  Amman - Jordan
                  <br />
                  Ras al-Ain - Al Quds St.
                  <br />
                  Azzahra Commercial Complex no. 100
                  <br />
                </p>
              </div>
              <div>
                <h3 className="contact-info-title">Contact Information</h3>
                <div>
                  <a href="tel:+96264743031" className="icon-info-container">
                    <PhoneIcon className="icon-font" />
                    <span className="icon-info">+962 6 474 3031 </span>
                  </a>
                  <a href="tel:+96264743032" className="icon-info-container">
                    <PhoneIcon className="icon-font" />
                    <span className="icon-info">+962 6 474 3032 </span>
                  </a>
                  <a href="tel:+96264743033" className="icon-info-container">
                    <PhoneIcon className="icon-font" />
                    <span className="icon-info"> +962 6 474 3033 </span>
                  </a>
                  <div className="icon-info-container">
                    <MailOutlineIcon className="icon-font" />
                    <span className="icon-info">info@azzahrapp.com</span>
                  </div>
                  <div className="icon-info-container">
                    <PrintIcon className="icon-font" />
                    <span className="icon-info">+962 6 474 3038</span>
                  </div>
                  <div className="icon-info-container">
                    <WhatsAppIcon className="icon-font" />
                    <span className="icon-info">+962 777660606</span>
                  </div>
                  <a
                    href="tel:+962777600017"
                    div
                    className="icon-info-container"
                  >
                    <PhoneAndroidIcon className="icon-font" />
                    <span className="icon-info">+962 777600017</span>
                  </a>
                  <div className="icon-info-container">
                    <AllInboxIcon className="icon-font" />
                    <span className="icon-info" style={{ textAlign: "left" }}>
                      P.O Box: 340935
                      <br />
                      Amman 11134 Jordan
                    </span>
                  </div>
                </div>
              </div>

              <div
                className="contact-info-box-3"
                style={{ display: "inline-block" }}
              >
                <h3
                  className="contact-info-title"
                  style={{
                    marginBottom: "10px",
                  }}
                >
                  Working Hours
                </h3>
                <Card
                  style={{
                    width: "100%",
                    float: "left",
                    clear: "left",
                    justifyContent: "space-between",
                    marginTop: "10px",
                  }}
                >
                  <table
                    style={{
                      float: "left",
                      clear: "left",
                      justifyContent: "space-between",
                      width: "100%",
                      padding: "2%",
                    }}
                  >
                    <tr>
                      <th
                        className="contact-info-desc"
                        style={{ fontWeight: " 600" }}
                      >
                        Days
                      </th>
                      <th
                        className="contact-info-desc"
                        style={{ fontWeight: " 600" }}
                      >
                        From
                      </th>
                      <th
                        className="contact-info-desc"
                        style={{ fontWeight: " 600" }}
                      >
                        To
                      </th>
                    </tr>
                    <tr>
                      <td className="contact-info-desc">Saturday</td>
                      <td className="contact-info-desc">8:00 am</td>
                      <td className="contact-info-desc">6:00 pm</td>
                    </tr>
                    <tr>
                      <td className="contact-info-desc">Sunday</td>
                      <td className="contact-info-desc">8:00 am</td>
                      <td className="contact-info-desc">6:00 pm</td>
                    </tr>
                    <tr>
                      <td className="contact-info-desc">Monday</td>
                      <td className="contact-info-desc">8:00 am</td>
                      <td className="contact-info-desc">6:00 pm</td>
                    </tr>
                    <tr>
                      <td className="contact-info-desc">Tuesday</td>
                      <td className="contact-info-desc">8:00 am</td>
                      <td className="contact-info-desc">6:00 pm</td>
                    </tr>
                    <tr>
                      <td className="contact-info-desc">Wednesday</td>
                      <td className="contact-info-desc">8:00 am</td>
                      <td className="contact-info-desc">6:00 pm</td>
                    </tr>
                    <tr>
                      <td className="contact-info-desc">Thursday</td>
                      <td className="contact-info-desc">8:00 am</td>
                      <td className="contact-info-desc">2:00 pm</td>
                    </tr>
                  </table>
                </Card>
              </div>
            </div>
            <Card className="Contact-Form-Box">{contactform}</Card>
          </div>
        </div>
        <div className="map-containter">
          <Map
            className="map-style"
            google={this.props.google}
            zoom={14}
            initialCenter={{ lat: 31.936613, lng: 35.9231185 }}
            style={{ height: "400px" }}
          >
            <Marker
              onClick={this.onMarkerClick}
              name={"Current location"}
              position={{ lat: 31.936613, lng: 35.9231185 }}
            />
            <InfoWindow onClose={this.onInfoWindowClose}></InfoWindow>
          </Map>
        </div>

        <FooterEng />
      </Fragment>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyDsRyZUloz5OVPmjHk5qN7OMOxCEu-yol4",
})(ContactusEng);

/**
 *             <h3 className="contact-info-title">Working Hours</h3>
                <p className="contact-info-desc">
                  from saturday to wednesday: 8am till 5:30pm
                </p>
                <p className="contact-info-desc">
                  on thursdays: 8am till 1:30pm
                  <br />
                </p>
 */
