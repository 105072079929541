import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./desknav.css";
//AboutUsEng
export class desknav extends Component {
  render() {
    return (
      <div className="desknav">
        <Link to="/" className="nav-btn" id="home">
          <span className="nav-btn-text">HOME</span>
        </Link>
        <Link to="/en/products" className="nav-btn" id="products">
          <span className="nav-btn-text">PRODUCTS</span>
        </Link>
        <Link to="/en/about-us" className="nav-btn" id="about">
          <span className="nav-btn-text">ABOUT US</span>
        </Link>
        <Link to="/en/contact-us" className="nav-btn" id="contact">
          <span className="nav-btn-text">CONTACT US</span>
        </Link>
      </div>
    );
  }
}

export default desknav;
