import React, { Component } from "react";
import "./popup.css";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";

import textbooks from "../../assets/product-imgs/textbook-magazines.jpg";
import calendar from "../../assets/product-imgs/calendar.jpg";
import securityimg from "../../assets/product-imgs/security-printing.jpg";
import paperbags from "../../assets/product-imgs/paper-bags.jpg";

import brochures from "../../assets/product-imgs/brochures.jpg";
import cartonboxes from "../../assets/product-imgs/carton-packages.png";
import cartonholders from "../../assets/product-imgs/carton-holder.png";
import paperplates from "../../assets/product-imgs/paper-plates.png";

import wallimg from "../../assets/product-imgs/wall-danglers.png";
import cases from "../../assets/product-imgs/folders.png";
import idprod from "../../assets/product-imgs/letterhead.jpg";
import commercialprints from "../../assets/product-imgs/commercial-prints.png";

import withStyles from "@material-ui/core/styles/withStyles";

const allimgs = {
  textbooks: textbooks,
  calendar: calendar,
  securityimg: securityimg,
  paperbags: paperbags,
  brochures: brochures,
  cartonboxes: cartonboxes,
  cartonholders: cartonholders,
  paperplates: paperplates,
  wallimg: wallimg,
  cases: cases,
  idprod: idprod,
  businessnb: commercialprints,
};

const styles = (theme) => ({
  Popupbox: {
    backgroundColor: "transparent",
    border: "none",
    height: "80%",
    width: "55%",
    "& @media only screen and (maxWidth: 700px)": {
      height: "50%",
      width: "55%",
    },
  },
});

const pop = {
  backgroundColor: "transparent",
  border: "none",
  height: "50%",
  width: "80%",
  "& @media only screen and (maxWidth: 700px)": {
    height: "50%",
    width: "55%",
  },
};

export class PopupEng extends Component {
  state = {
    imguTitle: "",
  };

  chosenImg() {
    for (const [key, value] of Object.entries(allimgs)) {
      if (this.props.imgProd === key) {
        return value;
      }
    }
  }

  /**
   * contentStyle={{
          border: "none",
          height: "500px",
          width: "500px",
          zIndex: "1500",
        }}

        "@media only screen and (max-width: 700px)": {
            height: "50%",
            width: "55%",
          },

          backgroundColor: "transparent",
          border: "none",
          height: "80%",
          width: "55%",

          classes.Popupbox
          contentStyle={pop}
   */

  render() {
    const { classes } = this.props;
    return (
      <Popup
        open={this.props.uibool}
        onClose={() => {
          this.props.onClosingPopUp();
        }}
        position="right center"
      >
        <div style={{ position: "relative", height: "100%", width: "100%" }}>
          <CloseOutlinedIcon
            style={{
              position: "absolute",
              top: "5px",
              right: "5px",
              zIndex: "1000",
            }}
            onClick={() => {
              this.props.onClosingPopUp();
            }}
          />
          <img
            src={this.chosenImg()}
            className="PopupImage"
            alt="product-img"
          />
        </div>
      </Popup>
    );
  }
}
/**
 *
 */
export default withStyles(styles)(PopupEng);
