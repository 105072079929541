import React, { Component } from "react";
/** 
import HomePageEng from "../EnglishPages/homepage-eng/homepage";
import AboutUsEng from "../EnglishPages/aboutus-eng/aboutus-eng";
import ProductEng from "../EnglishPages/products-eng/products-eng";
import ContactUsEng from "../EnglishPages/contactus-eng/contactus-eng";
*/
//import { BrowserRouter as Router } from "react-router-dom";

import MobileNavEng from "../components/EnglishComponents/header-eng/mobilenav/mobilenav";
import MobileNavAr from "../components/ArabicComponents/header-eng/mobilenav-ar/mobilenav-ar";
import BackDrop from "../UI/BackDrop";
import PageRouter from "./router";

import withStyles from "@material-ui/core/styles/withStyles";

const styles = (theme) => ({
  menuBarContainer: {
    position: "absolute",
    bottom: "0",
    height: "100vh",
    width: "230px",
    backgroundColor: "red",
    left: "0px",
    zIndex: "100",
    "@media(maxWidth: 500px)": {
      width: "320px",
    },
  },
  openMenu: {
    position: "absolute",
    bottom: "0",
    height: "100vh",
    width: "230px",
    backgroundColor: "red",
    zIndex: "100",
    transform: "translate(0)",
    transitionDuration: ".5s",
    transitionTimingFunction: "ease-in",
    "@media(maxWidth: 500px)": {
      width: "320px",
    },
  },
  closeMenu: {
    position: "absolute",
    bottom: "0",
    height: "100vh",
    width: "230px",
    backgroundColor: "red",
    zIndex: "100",
    transform: "translate(-370px)",
    transitionDuration: "1s",
    transitionTimingFunction: "ease-in",
    "@media(maxWidth: 500px)": {
      width: "320px",
    },
  },

  exitIcon: {
    position: "absolute",
    top: "5px",
    right: "5px",
  },
  minSize: {
    minWidth: "300px",
  },
});

class main extends Component {
  state = {
    setOpen: false,
  };

  handleClick = (bool) => {
    this.setState({ setOpen: bool });
  };
  render() {
    const { classes } = this.props;
    let menuAnimation = { left: "-230px" };
    if (this.state.setOpen) {
      menuAnimation = classes.openMenu;
    } else if (!this.state.setOpen) {
      menuAnimation = classes.closeMenu;
    }
    return (
      <div>
        {window.location.pathname.split("/")[1] === "ar" ? (
          <MobileNavAr
            menuAnimation={menuAnimation}
            handleClick={this.handleClick}
          />
        ) : (
          <MobileNavEng
            menuAnimation={menuAnimation}
            handleClick={this.handleClick}
          />
        )}

        <BackDrop
          menuAnimation={this.state.setOpen}
          handleClick={this.handleClick}
        />
        <body>
          <PageRouter handleClick={this.handleClick} />
        </body>
      </div>
    );
  }
}
//<FooterEng />
//<HeaderEng handleClick={this.handleClick} />

export default withStyles(styles)(main);
