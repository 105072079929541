import React, { Component, Fragment } from "react";
import "./aboutus-eng.css";

import HeaderEng from "../../../components/EnglishComponents/header-eng/header";
import FooterEng from "../../../components/EnglishComponents/footer-eng/footer";

export class AboutUs extends Component {
  render() {
    return (
      <Fragment>
        <HeaderEng handleClick={this.props.handleClick} />
        <div>
          <div>
            <div className="InfoBlockDeskCard1">
              <div
                className="InfoBlockDeskImageBtn"
                style={{ objectFit: "cover" }}
              >
                About Us
              </div>
            </div>

            <div className="about-us-row">
              <div className="about-us-text-block">
                <h3 className="about-us-text-block-title-eng">
                  Production Capabilities
                </h3>
                <p className="about-us-text-block-desc-eng">
                  Our work-space consists of 3000 m2, and it is equipped with
                  only the best machinery available in order to provide the best
                  services. The space is fully utilized to allow for the
                  highest-possible efficiency in the realm of parallel
                  production lines.
                </p>
              </div>

              <div className="about-us-img-block aboutus-img1"></div>
            </div>
          </div>

          <div className="about-us-row">
            <div className="about-us-img-block aboutus-img2"></div>

            <div className="about-us-text-block">
              <h3 className="about-us-text-block-title-eng">Our Team</h3>
              <p className="about-us-text-block-desc-eng">
                Our team at Al-Zahra’a is comprised of highly qualified
                employees that perform a wide variety of specialized jobs.
              </p>
            </div>
          </div>

          <div className="about-us-row">
            <div className="about-us-text-block">
              <h3 className="about-us-text-block-title-eng">Our Mission</h3>
              <p className="about-us-text-block-desc-eng">
                “To give our customers the best and most satisfactory printing
                experience possible.”
              </p>
            </div>

            <div className="about-us-img-block  aboutus-img3"></div>
          </div>

          <div className="about-us-row">
            <div className="about-us-img-block  aboutus-img4"></div>

            <div className="about-us-text-block">
              <h3 className="about-us-text-block-title-eng">Our Vision</h3>
              <p className="about-us-text-block-desc-eng">
                “To lead the region in delivering high-quality, professional
                printing solutions, while maintaining technological
                adaptability.”
              </p>
            </div>
          </div>

          <div className="about-us-row">
            <div className="about-us-text-block">
              <h3 className="about-us-text-block-title-eng">Our Core Values</h3>
              <p
                style={{ marginBottom: "10px" }}
                className="about-us-text-block-desc-eng"
              >
                1- Building relationships with clients based on integrity,
                trust, and respect.{" "}
              </p>

              <p
                style={{ marginBottom: "10px" }}
                className="about-us-text-block-desc-eng"
              >
                2- Maintaining professionalism, punctuality, and efficiency in
                every task we accomplish.
              </p>

              <p
                style={{ marginBottom: "10px" }}
                className="about-us-text-block-desc-eng"
              >
                3- Upholding high level standards to satisfy our client.{" "}
              </p>
            </div>

            <div className="about-us-img-block  aboutus-img5"></div>
          </div>
        </div>
        <FooterEng />
      </Fragment>
    );
  }
}

export default AboutUs;
