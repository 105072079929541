import React, { Component } from "react";
import "./footer.css";
import fblogo from "../../../assets/facebook-48.png";
import lklogo from "../../../assets/linkedin-3-48.png";
import instalogo from "../../../assets/instagram-48.png";
import ytlogo from "../../../assets/youtube-3-48.png";

export class footer extends Component {
  render() {
    return (
      <div className="footer" style={{ opacity: "100%" }}>
        <div className="socialbar">
          <img src={fblogo} className="social-logo" alt="Facebook-Logo" />
          <img src={lklogo} className="social-logo" alt="Linkedin-Logo" />
          <img src={instalogo} className="social-logo" alt="Instagram-Logo" />
          <img src={ytlogo} className="social-logo" alt="Youtube=Logo" />
        </div>
        <span className="copyright">
          © Copyright 2021 - AZZAHRA PRINT PRESS
        </span>
      </div>
    );
  }
}

export default footer;
